export var parseAdsProduct = function parseAdsProduct(adsResponse) {
  return adsResponse == null ? void 0 : adsResponse.map(function (product, index) {
    if (!Object.values(product).length) return null;
    return {
      available: true,
      brand: {
        label: product.brand
      },
      campaignId: product.campaignId,
      category: {
        name: product.category
      },
      id: product.id,
      image: product.image,
      path: '/',
      position: index + 1,
      price: product.price,
      rating: product.rating,
      seller: {
        id: product.seller.id
      },
      subcategory: {
        name: product.subcategory.name
      },
      title: product.title,
      trackId: product.trackId,
      url: product.url
    };
  }).filter(Boolean);
};