import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["title", "icon", "links", "newEntry"],
  _excluded2 = ["id"];
import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Button from '@magalu/stereo-ui/atoms/Button';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import StereoLink from '@magalu/stereo-ui/atoms/Link';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import { Icon } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
var Link = {
  button: Button,
  "default": StereoLink
};
var AccountCard = function AccountCard(_ref) {
  var title = _ref.title,
    icon = _ref.icon,
    links = _ref.links,
    newEntry = _ref.newEntry,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Box, _extends({
    bg: "background.base",
    p: 3
  }, props), React.createElement(Flex, {
    mb: 2,
    justifyContent: "space-between",
    alignItems: "center"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Heading, {
    as: "h2",
    color: "text.primary",
    mr: 2
  }, title), newEntry && React.createElement(Box, {
    p: 1,
    bg: "secondary.base",
    color: "#fff"
  }, "NOVO")), React.createElement(Icon, {
    name: icon,
    color: "background.primary",
    width: 40,
    height: 40
  })), React.createElement(Flex, {
    flexDirection: "column",
    gap: 2,
    alignItems: "flex-start"
  }, links.map(function (_ref2) {
    var type = _ref2.type,
      value = _ref2.value,
      href = _ref2.href;
    var LinkComponent = Link[type || 'default'];
    return React.createElement(LinkComponent, {
      uppercase: false,
      key: value,
      as: "a",
      size: "small",
      href: href
    }, value);
  })));
};
AccountCard.defaultProps = {
  newEntry: false
};
process.env.NODE_ENV !== "production" ? AccountCard.propTypes = {
  icon: string.isRequired,
  links: arrayOf(shape({
    href: string,
    type: string,
    value: string
  })).isRequired,
  newEntry: bool,
  title: string.isRequired
} : void 0;
var AccountCardList = function AccountCardList(_ref3) {
  var staticProps = _ref3["static"];
  return React.createElement(Grid, {
    gridGap: "30px",
    height: "100%"
  }, staticProps.items.map(function (_ref4) {
    var id = _ref4.id,
      props = _objectWithoutProperties(_ref4, _excluded2);
    return React.createElement(AccountCard, _extends({
      key: id
    }, props));
  }));
};
process.env.NODE_ENV !== "production" ? AccountCardList.propTypes = {
  "static": shape({
    items: arrayOf(shape(AccountCard.propTypes))
  }).isRequired
} : void 0;
AccountCardList.ssr = true;
export default withLayoutProps(AccountCardList);