export default [{
  id: '237689100',
  trackId: 'WyJlX1BKYm5OIiwiM',
  campaignId: '1122',
  variationId: null,
  title: 'Cerveja Heineken Lata 350ml 12 Unidades',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/cerveja-heineken-lata-350ml-12-unidades-lager-puro-malte/magazineluiza/237689100/181672997ea11893ec1f423ded45c723.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/237689100/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fcerveja-heineken-lata-350ml-12-unidades-lager-puro-malte%2Fp%2F237689100%2Fme%2Fcvej%2F%3Fpartner_id%3D62174',
  path: 'cerveja-heineken-lata-350ml-12-unidades-lager-puro-malte/p/237689100/me/cvej/',
  reference: null,
  offerTags: [],
  minimumOrderQuantity: null,
  attributes: [],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Heineken',
    slug: 'heineken',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '64.00',
    fullPrice: null,
    bestPrice: '52.53',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '53.06',
    totalAmount: '53.06',
    __typename: 'Installment'
  },
  rating: {
    count: 311,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'CVEJ',
    name: 'Cerveja',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '218940900',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Amaciante Downy Concentrado',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/amaciante-downy-concentrado-brisa-de-verao-15l/magazineluiza/218940900/2a74da25f748fc67416185f7b0333b17.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/218940900/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Famaciante-downy-concentrado-brisa-de-verao-15l%2Fp%2F218940900%2Fme%2Famro%2F%3Fpartner_id%3D62174',
  path: 'amaciante-downy-concentrado-brisa-de-verao-15l/p/218940900/me/amro/',
  reference: null,
  offerTags: ['magalu_indica'],
  minimumOrderQuantity: null,
  attributes: [{
    type: 'fragrancia',
    label: null,
    value: 'Brisa de Verão',
    current: null,
    values: null,
    __typename: 'Attribute'
  }, {
    type: 'volume',
    label: null,
    value: '1,5l',
    current: null,
    values: null,
    __typename: 'Attribute'
  }],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Downy',
    slug: 'downy',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '32.90',
    fullPrice: null,
    bestPrice: '25.64',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '25.90',
    totalAmount: '25.90',
    __typename: 'Installment'
  },
  rating: {
    count: 2705,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'AMRO',
    name: 'Amaciante',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '229400700',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Kit Sabão em Pó Omo Lavagem Perfeita 1,6kg',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/kit-sabao-em-po-omo-lavagem-perfeita-16kg-3-unidades/magazineluiza/229400700/65ca9a4541095f5b1ada3709e8099469.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/229400700/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fkit-sabao-em-po-omo-lavagem-perfeita-16kg-3-unidades%2Fp%2F229400700%2Fme%2Fsepo%2F%3Fpartner_id%3D62174',
  path: 'kit-sabao-em-po-omo-lavagem-perfeita-16kg-3-unidades/p/229400700/me/sepo/',
  reference: null,
  offerTags: [],
  minimumOrderQuantity: null,
  attributes: [],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Omo',
    slug: 'omo',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '119.97',
    fullPrice: null,
    bestPrice: '54.99',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '55.55',
    totalAmount: '55.55',
    __typename: 'Installment'
  },
  rating: {
    count: 34,
    score: 4,
    __typename: 'Rating'
  },
  badges: [{
    text: 'seloofertarelampago',
    imageUrl: 'https://i.mlcdn.com.br/selo-ml/{w}x{h}/b225b3ba-c1fd-11ee-97a5-02566cc712d2.png',
    container: 'image',
    position: 'top',
    tooltip: 'seloofertarelampago',
    __typename: 'Badge'
  }],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'SEPO',
    name: 'Sabão em Pó',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '239008900',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Kit Sabão Líquido Omo Lavagem Perfeita',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/kit-sabao-liquido-omo-lavagem-perfeita-3l-cada-2-unidades/magazineluiza/239008900/1be29dbac35960dc8308c43d76f0cf29.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/239008900/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fkit-sabao-liquido-omo-lavagem-perfeita-3l-cada-2-unidades%2Fp%2F239008900%2Fme%2Fsabl%2F%3Fpartner_id%3D62174',
  path: 'kit-sabao-liquido-omo-lavagem-perfeita-3l-cada-2-unidades/p/239008900/me/sabl/',
  reference: null,
  offerTags: [],
  minimumOrderQuantity: null,
  attributes: [],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Omo',
    slug: 'omo',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '119.80',
    fullPrice: null,
    bestPrice: '58.31',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '58.90',
    totalAmount: '58.90',
    __typename: 'Installment'
  },
  rating: {
    count: 184,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'SABL',
    name: 'Sabão Líquido',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '218044400',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Carga para Aparelho de Barbear Gillette',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/carga-para-aparelho-de-barbear-gillette-mach3-sensitive-16-cargas/magazineluiza/218044400/16c7239c853698f8e7eba02477a6ef14.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/218044400/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fcarga-para-aparelho-de-barbear-gillette-mach3-sensitive-16-cargas%2Fp%2F218044400%2Fme%2Fladb%2F%3Fpartner_id%3D62174',
  path: 'carga-para-aparelho-de-barbear-gillette-mach3-sensitive-16-cargas/p/218044400/me/ladb/',
  reference: null,
  offerTags: ['magalu_indica'],
  minimumOrderQuantity: null,
  attributes: [{
    type: 'quantity',
    label: null,
    value: '16 unidades',
    current: null,
    values: null,
    __typename: 'Attribute'
  }],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Gillette',
    slug: 'gillette',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '142.22',
    fullPrice: null,
    bestPrice: '96.02',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '96.99',
    totalAmount: '96.99',
    __typename: 'Installment'
  },
  rating: {
    count: 4122,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'LADB',
    name: 'Lâmina e Carga para Aparelho de Barbear',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '226984500',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Sabão Líquido Omo Lavanderia Profissional Lavagem Perfeita Profissional 7L',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/sabao-liquido-omo-lavanderia-profissional-lavagem-perfeita-profissional-7l/magazineluiza/226984500/31008a7f6013684f6be4724e660b60e7.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/226984500/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fsabao-liquido-omo-lavanderia-profissional-lavagem-perfeita-profissional-7l%2Fp%2F226984500%2Fme%2Fsabl%2F%3Fpartner_id%3D62174',
  path: 'sabao-liquido-omo-lavanderia-profissional-lavagem-perfeita-profissional-7l/p/226984500/me/sabl/',
  reference: null,
  offerTags: ['magalu_indica'],
  minimumOrderQuantity: null,
  attributes: [{
    type: 'volume',
    label: null,
    value: '7l',
    current: null,
    values: null,
    __typename: 'Attribute'
  }],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Omo',
    slug: 'omo',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '108.35',
    fullPrice: null,
    bestPrice: '69.29',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '69.99',
    totalAmount: '69.99',
    __typename: 'Installment'
  },
  rating: {
    count: 1980,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'SABL',
    name: 'Sabão Líquido',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '237817000',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Bebida Energética Red Bull The Summer Edition',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/bebida-energetica-red-bull-the-summer-edition-figo-e-maca-250ml/magazineluiza/237817000/3b85cf792f6207f94a7e7ed0ad5b59f8.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/237817000/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fbebida-energetica-red-bull-the-summer-edition-figo-e-maca-250ml%2Fp%2F237817000%2Fme%2Fengt%2F%3Fpartner_id%3D62174',
  path: 'bebida-energetica-red-bull-the-summer-edition-figo-e-maca-250ml/p/237817000/me/engt/',
  reference: null,
  offerTags: ['magalu_indica'],
  minimumOrderQuantity: null,
  attributes: [{
    type: 'volume',
    label: null,
    value: '250ml',
    current: null,
    values: null,
    __typename: 'Attribute'
  }, {
    type: 'flavor',
    label: null,
    value: 'Figo e Maçã',
    current: null,
    values: null,
    __typename: 'Attribute'
  }],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Red Bull',
    slug: 'red-bull',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '9.49',
    fullPrice: null,
    bestPrice: '3.95',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '3.99',
    totalAmount: '3.99',
    __typename: 'Installment'
  },
  rating: {
    count: 48,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'ENGT',
    name: 'Bebida Energética',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}, {
  id: '222260500',
  trackId: 'WyJlX1BKYm5OIiwiM',
  variationId: null,
  title: 'Sabão Líquido Brilhante Limpeza Total',
  description: null,
  image: 'https://a-static.mlcdn.com.br/{w}x{h}/sabao-liquido-brilhante-limpeza-total-5l/magazineluiza/222260500/413d72f5da04b7fffda522e6150fbc0a.jpg',
  available: null,
  position: null,
  isBuyBox: null,
  url: 'https://rgr.bob-recs.com/interactions/click/temp_8d76c690-53f1-11ef-aaaf-57339d4b3c92/cGFnZWRlc2tzaXRlLWRlc2tzaG1pZGRsZTtzaG93Y2FzZS0yNDE3OzQ1OzcyNjZhZTdmLWVhNTAtNDRjOS1hMzc2LWFlZmU3NGQxMzlkMw/222260500/?url=https%3A%2F%2Fwww.magazineluiza.com.br%2Fsabao-liquido-brilhante-limpeza-total-5l%2Fp%2F222260500%2Fme%2Fsabo%2F%3Fpartner_id%3D62174',
  path: 'sabao-liquido-brilhante-limpeza-total-5l/p/222260500/me/sabo/',
  reference: null,
  offerTags: ['magalu_indica'],
  minimumOrderQuantity: null,
  attributes: [{
    type: 'volume',
    label: null,
    value: '5l',
    current: null,
    values: null,
    __typename: 'Attribute'
  }],
  seller: {
    id: 'magazineluiza',
    sku: null,
    description: 'Magalu',
    category: '1p',
    deliveryId: null,
    deliveryDescription: null,
    details: null,
    __typename: 'Seller'
  },
  brand: {
    label: 'Brilhante',
    slug: 'brilhante',
    __typename: 'Brand'
  },
  price: {
    paymentMethodDescription: 'no Pix',
    price: '74.90',
    fullPrice: null,
    bestPrice: '37.61',
    discount: '1.00',
    currency: 'BRL',
    exchangeRate: null,
    idExchangeRate: null,
    originalPriceForeign: null,
    __typename: 'Price'
  },
  installment: {
    paymentMethodDescription: 'sem juros',
    quantity: 1,
    amount: '37.99',
    totalAmount: '37.99',
    __typename: 'Installment'
  },
  rating: {
    count: 1613,
    score: 5,
    __typename: 'Rating'
  },
  badges: [],
  category: {
    id: 'ME',
    name: 'Mercado',
    __typename: 'ProductCategory'
  },
  subcategory: {
    id: 'SABO',
    name: 'Sabão',
    __typename: 'ProductCategory'
  },
  loyalty: null,
  restrictions: null,
  __typename: 'Product',
  isOnWishlist: false,
  shippingTag: {
    cost: null,
    time: null,
    complement: null,
    __typename: 'ShippingTag'
  }
}];