import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useRef } from 'react';
import { number, shape, string } from 'prop-types';
import Showcase from '@magalu/stereo-ui/organisms/Showcase';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { usePublisher } from '@magalu/mixer-publisher';
import { parseAdsProduct } from './parseAdsProducts';
import mockAdsProducts from './__mocks__';
import { buildEventData } from './utils';
var AdsShowcase = function AdsShowcase(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var _staticProps$carousel = staticProps.carouselConfig,
    carouselConfig = _staticProps$carousel === void 0 ? {} : _staticProps$carousel,
    _staticProps$showcase = staticProps.showcase,
    showcase = _staticProps$showcase === void 0 ? {} : _staticProps$showcase,
    exhibition = staticProps.exhibition,
    titleAs = staticProps.titleAs,
    productTitleAs = staticProps.productTitleAs,
    jsonLD = staticProps.jsonLD,
    placement = staticProps.placement,
    showInCashLabel = staticProps.showInCashLabel,
    showDiscount = staticProps.showDiscount,
    showDiscountOnlyCashInfo = staticProps.showDiscountOnlyCashInfo;
  var ref = useRef(null);
  var products = parseAdsProduct(mockAdsProducts);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var eventData = buildEventData({
    exhibition: exhibition,
    placement: placement,
    showcase: showcase,
    structure: structure
  });
  var handleProductView = function handleProductView() {};
  var handleProductClick = function handleProductClick(e, product) {
    publish('productlist:selectItem:click', _extends({}, eventData, product));
  };
  return React.createElement(React.Fragment, null, !!(products == null ? void 0 : products.length) && React.createElement(Showcase, _extends({
    ref: ref,
    key: "ads-showcase-1",
    exhibition: exhibition,
    data: _extends({}, showcase == null ? void 0 : showcase.config, {
      products: products
    }),
    jsonLD: jsonLD,
    titleAs: titleAs,
    productTitleAs: productTitleAs,
    showInCashInfo: showInCashLabel,
    showDiscount: showDiscount,
    showDiscountOnlyCashInfo: showDiscountOnlyCashInfo,
    showSponsoredSubTitle: true,
    onProductView: handleProductView,
    onClick: handleProductClick
  }, carouselConfig)));
};
process.env.NODE_ENV !== "production" ? AdsShowcase.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    exhibition: string,
    name: string,
    page: string,
    slots: number
  }),
  structure: shape({})
} : void 0;
AdsShowcase.defaultProps = {
  data: {},
  "static": {
    exhibition: 'carousel',
    name: AdsShowcase,
    slots: 5
  },
  structure: {}
};
AdsShowcase.ssr = true;
export default withLayoutProps(AdsShowcase);