import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import styled from 'styled-components';
import { Box, Dialog } from '@magalu/stereo-ui';
import { Text, Button as StereoButton } from '@magalu/stereo-ui/atoms';
export var Button = styled(StereoButton).attrs(function (props) {
  return _extends({
    backgroundColor: 'white'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 12px;\n\n  > label {\n    text-transform: none !important;\n    min-width: 226px;\n    font-size: 16px;\n  }\n"])));
export var CloseButton = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  align-items: center;\n  background-color: #e3e8ea;\n  border-radius: 100%;\n  cursor: pointer;\n  display: flex;\n  height: 32px;\n  justify-content: center;\n  position: absolute;\n  right: 16px;\n  top: 16px;\n  width: 32px;\n"])));
export var ContainerActions = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 8px;\n  justify-content: center;\n  width: 100%;\n"])));
export var ContainerHeader = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-bottom: 16px;\n  svg {\n    flex-shrink: 0;\n  }\n"])));
export var DialogStyled = styled(Dialog)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  border-radius: 24px;\n  max-width: 680px;\n  padding: 16px;\n  position: relative;\n\n  svg {\n    flex-shrink: 0;\n  }\n"])));
export var InfoText = styled(Text).attrs(function (props) {
  return _extends({
    color: '#51585c',
    fontSize: '16px',
    lineHeight: '18px',
    width: '100%'
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-bottom: 16px;\n"])));
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    color: '#51585c',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '26px'
  }, props);
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin-bottom: 16px;\n"])));